import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export const query = graphql`
  {
      allSanityAuthor {
        edges {
          node {
            id
            name
          }
        }
      }
    }
`

const ProfilePage = props => {
  return (
    <Layout>
      <h1>Profiles</h1>
      <ul>
        {props.data.allSanityAuthor.edges.map(edge => (
          <li>
            <h1>{edge.name}</h1>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default ProfilePage